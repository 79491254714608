/* eslint-disable class-methods-use-this */
import _axios, { get, put, _delete } from '@/lin/plugin/axios'

// 我们通过 class 这样的语法糖使模型这个概念更加具象化，其优点：耦合性低、可维护性。
class Meter {

  type = null

  key = null


  constructor({
    lPage = 0,
    lCount = 10,
  }) {
    if (lPage === 0) {
      this.lPage = lPage
    }
    if (lCount) {
      this.lCount = lCount
    }
  }

  async getMeters({type, key, count, page, next = false }) {
    return _axios({
      method: 'get',
      url: '/v1/meter',
      params: {
        count: count || this.lCount,
        page: page || this.lPage,
        name: type || this.type,
        start: key || this.key
      },
      handleError: true,
    })
  }
}

export default new Meter({})
